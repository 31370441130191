<template>
	<v-app :class="($vuetify.breakpoint.smAndDown ? 'on-mobile' : '')">
		<Drawer v-model="drawer" :menu="menu" @drawer="drawer = false"></Drawer>
		<Toolbar :menu="menu" :drawer="drawer" @drawer="drawer = !drawer"></Toolbar>
		<v-main>
			<router-view ref="routerview"></router-view>
		</v-main>
		<Footer :menu="menu"></Footer>
	</v-app>
</template>

<style>
html, .v-application {
	font-size: 16px !important;
	font-family: 'Open Sans', sans-serif;
	scroll-behavior: smooth;
}
@media screen and (max-width: 800px) {
	html, .v-application {
		font-size: 14px !important;
	}
}
/* @media screen and (max-width: 600px) {
	html, .v-application {
		font-size: 12px !important;
	}
} */
/* @media screen and (max-width: 450px) {
	html, .v-application {
		font-size: 10px !important;
	}
} */
.center-all {
	display: flex;
	justify-content: center;
	align-items: center;
}
.fill-width {
	width: 100%;
}
.small-icon {
	font-size: 0.8rem !important;
}
.circular {
	border-radius: 50%;
}
h1.headline-title {
	font-size: 1.8rem !important;
	margin-bottom: 1.6rem;
	position: relative;
	width: fit-content;
	/* font-weight: 400; */
	font-weight: 600;
}
/* h1.headline-title:after {
	content: '';
	position: absolute;
    bottom: -0.5rem;
	left: 0;
    width: 120%;
	max-width: 80vw;
	height: 0.1rem;
	background-color: var(--v-primary-base);
}
h1.headline-title.white--text:after {
	background-color: #ffffff;
} */

/* Line Helper Classes */
.line-top {
	position: relative;
	padding-top: 1.2rem;
}
.line-top:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 1.2rem;
    background-color: var(--v-primary-base);
	z-index: 2;
}

/* BG Helper Classes */
.full-bg {
	position: relative;
}
.full-bg:before, .full-bg:after {
	z-index: -1;
}
.half-bg:before, .full-bg:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: calc(50% + 1.2rem);
    height: 100%;
    background-color: var(--v-primary-base);
}
.half-bg:after, .full-bg:after {
    position: absolute;
    content: '';
    top: 0;
    left: -1.2rem;
    width: calc(50% + 1.2rem);
    height: 100%;
    background-color: var(--v-secondary-base);
}
.half-bg.right:before {
	left: 50%;
}
.half-bg.right:after {
	left: calc(50% + 1.2rem);
}
.full-bg:before {
	width: 100%;
}
.full-bg:after {
	width: 100%;
	left: 0;
}

a.p-btn {
	position: relative;
	font-size: 1.2rem;
	font-weight: 600;
	text-decoration: none;
}
a.p-btn:before {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: -1rem;
    left: 0;
    background: var(--v-primary-base);
    width: 100%;
    height: 3px;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    transition: -webkit-transform .6s cubic-bezier(.215,.61,.355,1) 0s;
    transition: transform .6s cubic-bezier(.215,.61,.355,1) 0s;
    transition: transform .6s cubic-bezier(.215,.61,.355,1) 0s,-webkit-transform .6s cubic-bezier(.215,.61,.355,1) 0s;
}
a.p-btn.white--text:before {
	background: #ffffff;
}
a.p-btn:hover:before {
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: -webkit-transform .6s cubic-bezier(.215,.61,.355,1) 0s;
    transition: transform .6s cubic-bezier(.215,.61,.355,1) 0s;
    transition: transform .6s cubic-bezier(.215,.61,.355,1) 0s,-webkit-transform .6s cubic-bezier(.215,.61,.355,1) 0s;
}
</style>

<script>
import Drawer from './components/Layout/Drawer'
import Toolbar from './components/Layout/Toolbar'
import Footer from './components/Layout/Footer'
export default {
	name: 'App',
	components: {
		Drawer,
		Toolbar,
		Footer
	},
	data () {
		return {
			drawer: false,
			menu: [
				{
					name: '$vuetify.route.home',
					to: '/'
				},
				{
					name: '$vuetify.route.company_profile',
					to: '/company'
				},
				{
					name: '$vuetify.route.portfolio',
					to: '/portfolio'
				},
				{
					name: '$vuetify.route.services',
					to: '/services'
				}
			]
		}
	},
	watch: {
		'$route.hash': {
			handler (hash) {
				if (!hash || hash == '') return
				setTimeout(() => {
					let element = document.getElementById(hash.substring(1))
					if (element) element.scrollIntoView()
					history.pushState('', document.title, window.location.pathname + window.location.search)
				}, 500)
			},
			deep: true
		}
	},
	mounted () {
		this.$vuetify.lang.current = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
		let instance = this
		window.onscroll = function(e) {
			if(instance.$route.meta.services) instance.$refs.routerview.on_scroll()
		}
	}
}
</script>
