<template>
	<div id="portfolio" class="mb-16">
        <Heading :heading="$vuetify.lang.t('$vuetify.route.portfolio')"></Heading>
        <div v-if="portfolio_categories" class="category-selection text-center mx-4 mb-8">
            <v-btn v-for="(category, c) in portfolio_categories" :key="c"
                depressed rounded color="primary" :outlined="category != selected_category" class="mx-2"
                @click="selected_category = category">
                <template v-if="category == 'all'">{{ $vuetify.lang.t('$vuetify.portfolio.all') }}</template>
                <template v-else>{{ category | camelCase }}</template>
            </v-btn>
        </div>
        <PortfolioItem v-for="(work, i) in portfolio_filtered" :key="work.id" :work="work" :background="i % 2 ? true : false"></PortfolioItem>
	</div>
</template>

<script>
import Heading from '@/components/Layout/Heading'
import PortfolioItem from '@/components/Portfolio/PortfolioItem'
export default {
	name: 'Portfolio',
	components: {
        Heading,
        PortfolioItem
    },
    data () {
        return {
            portfolio: null,
            selected_category: 'all'
        }
    },
    computed: {
        portfolio_categories () {
            if (!this.portfolio) return null
            let cat_array = []
            cat_array = this.portfolio.map(item => item.category)?.filter((value, index, self) => self.indexOf(value) === index)
            cat_array.unshift('all')
            return cat_array
        },
        portfolio_filtered () {
            if (this.selected_category == 'all') return this.portfolio
            return this.portfolio?.filter(p => p.category == this.selected_category)
        }
    },
    watch: {
        selected_category (v) {
            if (this.$route.params.category != v) this.$router.replace(`/portfolio/${v}`)
        }
    },
    mounted () {
        if (this.$route.params.category) this.selected_category = this.$route.params.category
        this.getPortfolio()
    },
    methods: {
        getPortfolio () {
            axios.get('/portfolio/data.json')
                .then(resp => {
                    this.portfolio = resp.data.portfolio
                })
        }
    }
}
</script>