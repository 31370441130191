<template>
	<div id="services">
        <Heading :heading="$vuetify.lang.t('$vuetify.route.services')" :cutout="2"></Heading>
        <InfoItems :items="how_it_works" :md_cols="6" :lg_cols="6" :xl_cols="4" :hide_description="false" class="pb-0"></InfoItems>
        <!-- <div class="line-top"></div> -->
        <ServicesBoat ref="servicesBoat"></ServicesBoat>
	</div>
</template>

<style scoped>
.services-container {
    overflow: hidden;
}
.service-items {
    cursor: pointer;
    text-decoration: underline;
    padding: 0.5rem initial;
}
.images-container {
    position: relative;
    padding: 2rem;
    /* height: inherit;
    width: -webkit-fill-available; */
}
.images-container .v-image {
    border-radius: 16px;
}
.floating-images-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    /* overflow: hidden; */
}
.floating-image {
    position: relative;
}
.floating-image > .v-image {
    position: absolute;
}
.floating-image.top-left > .v-image {
    top: 0;
    left: 0;
}
.floating-image.top-right > .v-image {
    top: 0;
    right: 0;
}
.floating-image.bottom-left > .v-image {
    bottom: 0;
    left: 0;
}
.floating-image.bottom-right > .v-image {
    bottom: 0;
    right: 0;
}
</style>

<script>
import Heading from '@/components/Layout/Heading'
import InfoItems from '@/components/InfoItems'
import ServicesBoat from '@/components/Services/ServicesBoat'
export default {
	name: 'Services',
	components: {
        Heading,
        InfoItems,
        ServicesBoat
    },
    data () {
        return {
            how_it_works: [
                {
                    icon: 'design-1.png',
                    title: '$vuetify.services.design.name',
                    description: '$vuetify.services.design.description',
                    related_work: '/portfolio/ballastWaterTreatmentSystem'
                },
                {
                    icon: 'fabrication-1.jpeg',
                    title: '$vuetify.services.fabrication.name',
                    description: '$vuetify.services.fabrication.description',
                    related_work: '/portfolio/ballastWaterTreatmentSystem'
                },
                {
                    icon: 'installation-1.jpeg',
                    title: '$vuetify.services.installation.name',
                    description: '$vuetify.services.installation.description',
                    related_work: '/portfolio/ballastWaterTreatmentSystem'
                },
                {
                    icon: 'repair-1.jpeg',
                    title: '$vuetify.services.repair.name',
                    description: '$vuetify.services.repair.description',
                    related_work: '/portfolio/ballastWaterTreatmentSystem'
                },
                {
                    icon: 'testing-1.jpeg',
                    title: '$vuetify.services.testing.name',
                    description: '$vuetify.services.testing.description',
                    related_work: '/portfolio/ballastWaterTreatmentSystem'
                },
                {
                    icon: 'testing-1.jpeg',
                    title: '$vuetify.services.certifications.name',
                    description: '$vuetify.services.certifications.description',
                    related_work: '/portfolio/ballastWaterTreatmentSystem'
                },
            ],
            services: [
                {
                    name: '$vuetify.services.design.name',
                    description: '$vuetify.services.design.description',
                    images: [
                        'design-1.png',
                        'design-2.png'
                    ]
                },
                {
                    name: '$vuetify.services.fabrication.name',
                    description: '$vuetify.services.fabrication.description',
                    images: [
                        'fabrication-1.jpeg',
                        'fabrication-2.jpeg',
                        'fabrication-3.jpeg'
                    ]
                },
                {
                    name: '$vuetify.services.installation.name',
                    description: '$vuetify.services.installation.description',
                    images: [
                        'installation-1.jpeg',
                        'installation-2.jpeg',
                        'installation-3.jpeg'
                    ]
                },
                {
                    name: '$vuetify.services.repair.name',
                    description: '$vuetify.services.repair.description',
                    images: [
                        'repair-1.jpeg',
                        'repair-2.jpeg',
                        'repair-3.jpeg'
                    ]
                },
                {
                    name: '$vuetify.services.testing.name',
                    description: '$vuetify.services.testing.description',
                    images: [
                        'testing-3.jpeg',
                        'testing-2.jpeg',
                        'testing-1.jpeg'
                    ]
                },
                {
                    name: 'General Factory Repairs',
                    items: [
                        {
                            name: 'Burner Cones for IGG',
                            images: [
                                'faculty-repair-1.jpeg',
                                'faculty-repair-2.jpeg',
                                'faculty-repair-3.jpeg',
                                'faculty-repair-4.jpeg'
                            ]
                        },
                        {
                            name: 'Smag Grab Cable Drum',
                            images: [
                                'faculty-repair-5.jpeg',
                                'faculty-repair-6.jpeg'
                            ]
                        },
                        {
                            name: 'Deflection Sheaves LRN 350',
                            images: [
                                'faculty-repair-7.jpeg',
                                'faculty-repair-8.jpeg'
                            ]
                        },
                        {
                            name: 'Rope Stabilizing Drum',
                            images: [
                                'faculty-repair-9.jpeg',
                                'faculty-repair-10.jpeg'
                            ]
                        },
                        {
                            name: 'Deflection Sheaves URN 350',
                            images: [
                                'faculty-repair-11.jpeg',
                                'faculty-repair-12.jpeg'
                            ]
                        },
                        {
                            name: 'Deflection Sheaves URN 500',
                            images: [
                                'faculty-repair-13.jpeg',
                                'faculty-repair-14.jpeg'
                            ]
                        },
                        {
                            name: 'Mushroom',
                            images: [
                                'faculty-repair-15.jpeg'
                            ]
                        },
                        {
                            name: 'Air Vents',
                            images: [
                                'faculty-repair-16.jpeg'
                            ]
                        },
                        {
                            name: 'Air Vent Inox Discs',
                            images: [
                                'faculty-repair-17.jpeg'
                            ]
                        }
                    ],
                    active_item: null,
                    placeholder: 'Select a repair type by hovering on it'
                }
            ]
        }
    },
    mounted () {
    },
    methods: {
        on_scroll () {
            this.$refs.servicesBoat.on_scroll()
        }
    }
}
</script>