<template>
	<div id="home">
		<Intro></Intro>
		<WhyChooseUs></WhyChooseUs>
		<OurWork></OurWork>
		<InfoItems :section_title="$vuetify.lang.t('$vuetify.services.title')" :xl_cols="2" :items="how_it_works" :hide_description="true" class="pb-0"></InfoItems>
		<div class="center-all pt-2 pb-12">
			<router-link class="p-btn primary--text" to="/services">{{ $vuetify.lang.t('$vuetify.services.c2a') }}</router-link>
		</div>
	</div>
</template>

<style scoped>
.intro {
	position: fixed;
	top: 0;
	left: 72px;
	width: 100%;
	z-index: 0;
}
.on-mobile .intro {
	left: 0;
}
.why-choose-us {
	margin-top: 100vh;
}
#home > *:not(.intro) {
	position: relative;
	z-index: 1;
	background-color: var(--v-background-base);
}
</style>

<script>
import Intro from '@/components/Home/Intro'
import WhyChooseUs from '@/components/Home/WhyChooseUs'
import OurWork from '@/components/Home/OurWork'
import InfoItems from '@/components/InfoItems'
export default {
	name: 'Home',
	components: {
		Intro,
		WhyChooseUs,
		OurWork,
		InfoItems
	},
	data: () => ({
		how_it_works: [
            {
                icon: 'design-1.png',
                title: '$vuetify.services.design.name',
                description: '$vuetify.services.design.description'
            },
            {
                icon: 'fabrication-1.jpeg',
                title: '$vuetify.services.fabrication.name',
                description: '$vuetify.services.fabrication.description'
            },
            {
                icon: 'installation-1.jpeg',
                title: '$vuetify.services.installation.name',
                description: '$vuetify.services.installation.description'
            },
            {
                icon: 'repair-1.jpeg',
                title: '$vuetify.services.repair.name',
                description: '$vuetify.services.repair.description'
            },
            {
                icon: 'testing-1.jpeg',
                title: '$vuetify.services.testing.name',
                description: '$vuetify.services.testing.description'
            },
        ]
	})
}
</script>
