<template>
	<div id="company-profile">
        <Heading :heading="$vuetify.lang.t('$vuetify.route.company_profile')" :cutout="3"></Heading>
        <v-container class="profile-container pa-6">
            <h1 class="headline-title">{{ $appName }}</h1>
            <p v-html="$vuetify.lang.t('$vuetify.company_profile.text')"></p>
            <v-lazy transition="scroll-y-transition">
                <v-img contain :src="require('@/assets/logo_circular.png')" height="14rem" class="mt-4"></v-img>
            </v-lazy>
            <v-lazy transition="scroll-y-transition">
                <v-img contain :src="require('@/assets/umbrella.svg')" height="14rem" class="mt-12" style="margin-bottom: -120px;"></v-img>
            </v-lazy>
            <v-timeline class="pt-12">
                <v-timeline-item v-for="(company, i) in companies" :key="i" :color="company.color" small>
                <template v-slot:opposite>
                    <v-lazy transition="scroll-y-transition">
                        <span class="headline font-weight-bold" :style="`color: ${company.color}`" v-text="company.year"></span>
                    </v-lazy>
                </template>
                <div class="py-4">
                    <v-lazy transition="scroll-y-transition">
                        <!-- <h2 class="headline font-weight-light mb-4" :style="`color: ${company.color}`">
                            {{ company.name }}
                        </h2> -->
                        <img :src="company.logo" style="max-height: 96px; max-width: 100%;" :class="company.logo_margin ? 'mb-2' : ''"/>
                    </v-lazy>
                    <v-lazy transition="scroll-y-transition">
                        <div v-if="company.info" v-html="company.info"></div>
                    </v-lazy>
                </div>
                </v-timeline-item>
            </v-timeline>
            <v-lazy transition="scroll-y-transition">
                <v-img contain :src="require('@/assets/umbrella_handle.svg')" height="14rem" class="mb-12" style="margin-top: -180px; z-index: 1;"></v-img>
            </v-lazy>
        </v-container>
	</div>
</template>

<style>
.profile-container .v-timeline:before {
    background: rgba(79, 101, 142, 0.7);
    width: 4px;
}
.profile-container .v-timeline a {
    display: inline-block;
    margin-top: 1rem;
    text-decoration: none;
    font-weight: 600;
}
.profile-container .v-timeline a:hover {
    text-decoration: underline;
}
</style>

<script>
import Heading from '@/components/Layout/Heading'
export default {
	name: 'CompanyProfile',
	components: {
        Heading
    },
    data () {
        return {
            portfolio: null,
            companies: [
                {
                    color: '#4f658e',
                    year: '2020',
                    logo: require('@/assets/logo.png'),
                    logo_margin: false,
                    name: 'PipeMAN',
                    info: 'PipeMAN undertakes work along the vessel or on the anchorage as well as prefabricated in our own factory based on design. The company and the craftsmen are certified by industry standards and the materials used are always accompanied by certificates of suitability as well. When work concerns welds then are monitoring by a certified welding engineer.'
                },
                {
                    color: '#dd2a1c',
                    year: '2013',
                    logo: require('@/assets/umbrella_logos/MAC.png'),
                    logo_margin: false,
                    name: 'MAC',
                    info: 'M.A.C. has developed, implemented and constantly applies a System of Quality Management, aiming to ensure the quality of products and services it provides as well as to perpetually improve the aforementioned system.'
                },
                {
                    color: '#192d3c',
                    year: '1991',
                    logo: require('@/assets/umbrella_logos/aeolos.png'),
                    logo_margin: true,
                    name: 'Aeolos',
                    info: 'Aeolos was founded by experienced and skilled technicians for the fire safety and fire protection of land and sea. Has been dynamically integrated into the marine sector at the retail level.'
                },
                {
                    color: '#7e96a7',
                    year: '1985',
                    logo: require('@/assets/umbrella_logos/vasmare.gif'),
                    logo_margin: true,
                    name: 'Vas - Mare',
                    info: 'Vas - Mare operates in the general ship & shore factory repairs industries.The Company\'s warranty is their Quality dedicated to serving customers with high quality fabricated auxiliary ship equipment products and services.'
                },
                {
                    color: '#c8733d',
                    year: '1964',
                    logo: require('@/assets/umbrella_logos/shipboiler.png'),
                    logo_margin: false,
                    name: 'ShipBoiler',
                    info: 'ShipBoiler is a company that manufactures, commercialises and installs, high quality boilers for ships and factories around the world. It means that the specialized staff designs, welds, tests and assembles the parts of any given type of boiler, onboard or in a factory.'
                },
            ],
        }
    }
}
</script>