import { render, staticRenderFns } from "./CompanyProfile.vue?vue&type=template&id=0c5be6c8&"
import script from "./CompanyProfile.vue?vue&type=script&lang=js&"
export * from "./CompanyProfile.vue?vue&type=script&lang=js&"
import style0 from "./CompanyProfile.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VContainer,VImg,VLazy,VTimeline,VTimelineItem})
