<template>
    <div class="intro">
        <kinesis-container class="container intro-container center-all fill-height">
            <v-layout row>
                <v-flex xs12 md6 class="center-all">
                    <kinesis-element :strength="10" class="text-container">
                        <v-lazy transition="scroll-x-transition">
                            <div class="p-title mb-4">{{ $vuetify.lang.t('$vuetify.intro.title') }}</div>
                        </v-lazy>
                        <v-lazy transition="scroll-x-transition">
                            <p class="p-subtitle">{{ $vuetify.lang.t('$vuetify.intro.subtitle') }}</p>
                        </v-lazy>
                    </kinesis-element>
                </v-flex>
                <v-flex xs12 md6 class="center-all">
                    <v-lazy transition="scroll-y-transition" class="fill-height fill-width">
                        <kinesis-element :strength="10" class="fill-height fill-width center-all">
                            <v-img class="image-container" contain :src="require('@/assets/logo_circular.png')"></v-img>
                        </kinesis-element>
                    </v-lazy>
                </v-flex>
            </v-layout>
        </kinesis-container>
        <img class="scroll-down" :src="require('@/assets/scroll_animation.gif')">
    </div>
</template>

<style scoped>
.intro {
    position: relative;
    background-image: linear-gradient(to bottom, rgba(79, 101, 142, 0.7), var(--v-primary-base)), url('~@/assets/intro-background.jpeg');
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
    overflow: hidden;
}
.intro-container {
    padding-top: 110px;
    padding-bottom: 48px;
    min-height: 100vh;
}
.text-container {
    position: relative;
    padding: 2rem;
    color: #ffffff;
}
.p-title {
    font-size: 3rem;
    font-weight: 900;
}
.on-mobile .p-title, .on-mobile .p-subtitle {
    text-align: center;
}
.p-subtitle {
    font-size: 1.5rem;
}
.image-container {
    width: 60%;
    max-height: 20rem;
    padding: 1.5rem 0;
    margin: 1rem;
    z-index: 1;
}
.intro .scroll-down {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    height: 5rem;
    padding: 1rem;
}
</style>

<script>
export default {
    name: 'Intro'
}
</script>