<template>
    <div class="our-work primary">
        <v-container>
            <v-layout row>
                <v-flex xs12 class="px4 py-8 center-all">
                    <div class="fill-width">
                        <h1 class="headline-title white--text">{{ $vuetify.lang.t('$vuetify.our_work.title') }}</h1>
                        <v-lazy transition="scroll-y-transition">
                            <v-slide-group show-arrows>
                                <v-slide-item v-for="(work, i) in portfolio" :key="work.id">
                                    <v-card class="ma-4" width="17.5rem" :class="i == 0 ? 'ml-0' : ''" :to="`/portfolio#work${work.id}`">
                                        <v-hover v-slot:default="{ hover }">
                                            <v-img class="white--text align-end" height="15rem" :src="Array.isArray(work.images) && work.images.length > 0 ? '/portfolio/images/' + encodeURI(work.images[0]) : null">
                                                <v-card-title v-if="!hover">{{ work.title }}</v-card-title>
                                                <v-sheet v-else color="secondary white--text" style="height: 15rem;" class="fill-width pa-4">
                                                    <div style="position: absolute; bottom: 0; width: calc(100% - 32px);">
                                                        <h2 class="mb-4">{{ work.title }}</h2>
                                                    </div>
                                                </v-sheet>
                                            </v-img>
                                        </v-hover>
                                    </v-card>
                                </v-slide-item>
                            </v-slide-group>
                        </v-lazy>
                        <div class="text-center fill-width py-4">
                            <router-link class="p-btn white--text" to="/portfolio">{{ $vuetify.lang.t('$vuetify.our_work.c2a') }}</router-link>
                        </div>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<style scoped>
.card-container {
    display: flex;
    overflow-x: auto;
}
.v-sheet.secondary {
    background-color: rgba(79, 101, 142, 0.8) !important;
}
</style>

<style>
.our-work .v-slide-group__content {
    justify-content: center;
}
</style>

<script>
import Rellax from 'rellax'
export default {
    name: 'OurWork',
    data () {
        return {
            portfolio: null
        }
    },
    mounted () {
		new Rellax('.rellax', {
			// wrapper: '.rellax-wrapper'
			center: true,
			round: false
        })
        this.getPortfolio()
    },
    methods: {
        getPortfolio () {
            axios.get('/portfolio/data.json')
                .then(resp => {
                    this.portfolio = resp.data.portfolio
                    if (this.portfolio.length > 5) this.portfolio.length = 5
                })
        }
    }
}
</script>