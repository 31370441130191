<template>
    <div class="why-choose-us background">
        <!-- <v-container class="py-0" :class="$vuetify.breakpoint.mdAndUp ? 'half-bg right' : ''"> -->
        <v-container>
            <v-row>
                <v-flex xs12 md6 class="pa-6 center-all">
                    <div>
                        <v-lazy transition="scroll-y-transition"><h1 class="headline-title">{{ $vuetify.lang.t('$vuetify.why_choose_us.title') }}</h1></v-lazy>
                        <v-lazy transition="scroll-y-transition">
                            <p v-html="$vuetify.lang.t('$vuetify.why_choose_us.text')"></p>
                        </v-lazy>
                        <v-lazy transition="scroll-y-transition">
                            <router-link class="p-btn" to="/company">{{ $vuetify.lang.t('$vuetify.why_choose_us.c2a') }}</router-link>
                        </v-lazy>
                    </div>
                </v-flex>
                <!-- <v-flex xs12 md6 :class="!$vuetify.breakpoint.mdAndUp ? 'full-bg center-all pt-8' : 'pa-8'"> -->
                <v-flex xs12 md6 class="pa-6 center-all">
                    <kinesis-container class="images-container">
                        <kinesis-element :strength="10">
                            <v-lazy transition="scroll-y-transition">
                                <v-img v-if="images[0]" :src="images[0].src" :class="images[0].loaded ? 'circular elevation-4' : ''" height="25rem" width="25rem" @load="images[0].loaded = true"></v-img>
                            </v-lazy>
                        </kinesis-element>
                        <div class="floating-images-container">
                            <v-row class="fill-height">
                                <v-flex xs6 class="floating-image top-left">
                                    <kinesis-element :strength="30">
                                        <v-lazy transition="scroll-y-transition">
                                            <v-img v-if="images[1]" :src="images[1].src" :class="images[1].loaded ? 'circular elevation-4' : ''" height="12rem" width="12rem" @load="images[1].loaded = true"></v-img>
                                        </v-lazy>
                                    </kinesis-element>
                                </v-flex>
                                <v-flex xs6 class="floating-image top-right">
                                    <kinesis-element :strength="30">
                                        <v-lazy transition="scroll-y-transition">
                                            <v-img v-if="images[4]" :src="images[4].src" :class="images[4].loaded ? 'circular elevation-4' : ''" height="12rem" width="12rem" @load="images[4].loaded = true"></v-img>
                                        </v-lazy>
                                    </kinesis-element>
                                </v-flex>
                                <v-flex xs6 class="floating-image bottom-left">
                                    <kinesis-element :strength="30">
                                        <v-lazy transition="scroll-y-transition">
                                            <v-img v-if="images[3]" :src="images[3].src" :class="images[3].loaded ? 'circular elevation-4' : ''" height="12rem" width="12rem" @load="images[3].loaded = true"></v-img>
                                        </v-lazy>
                                    </kinesis-element>
                                </v-flex>
                                <v-flex xs6 class="floating-image bottom-right">
                                    <kinesis-element :strength="30">
                                        <v-lazy transition="scroll-y-transition">
                                            <v-img v-if="images[2]" :src="images[2].src" :class="images[2].loaded ? 'circular elevation-4' : ''" height="12rem" width="12rem" @load="images[2].loaded = true"></v-img>
                                        </v-lazy>
                                    </kinesis-element>
                                </v-flex>
                            </v-row>
                        </div>
                    </kinesis-container>
                </v-flex>
            </v-row>
        </v-container>
    </div>
</template>

<style scoped>
.why-choose-us {
    position: relative;
}
.why-choose-us p span {
    color: var(--v-primary-base);
    font-weight: 600;
}
.why-choose-us, .why-choose-us > .container, .why-choose-us > .container > .row > .flex {
    height: 100%;
    min-height: 50vh;
    overflow: hidden;
}
.why-choose-us .container .row {
    position: relative;
    z-index: 1;
}
.images-container {
    position: relative;
    height: 100%;
    /* width: 100%; */
    margin: 2rem;
}
.floating-images-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.floating-image {
    position: relative;
}
.floating-image > .v-image {
    position: absolute;
}
.floating-image.top-left > .v-image {
    top: 0;
    left: 0;
}
.floating-image.top-right > .v-image {
    top: 0;
    right: 0;
}
.floating-image.bottom-left > .v-image {
    bottom: 0;
    left: 0;
}
.floating-image.bottom-right > .v-image {
    bottom: 0;
    right: 0;
}
</style>

<script>
export default {
    name: 'WhyChooseUs',
    data () {
        return {
        }
    },
    data: () => ({
        images: [
            {
                loaded: false,
                src: require('@/assets/slideshow-1.jpeg')
            },
            {
                loaded: false,
                src: require('@/assets/services/testing-2.jpeg')
            },
            {
                loaded: false,
                src: require('@/assets/slideshow-3.jpg')
            }
        ]
    })
}
</script>