import { render, staticRenderFns } from "./OurWork.vue?vue&type=template&id=435139cf&scoped=true&"
import script from "./OurWork.vue?vue&type=script&lang=js&"
export * from "./OurWork.vue?vue&type=script&lang=js&"
import style0 from "./OurWork.vue?vue&type=style&index=0&id=435139cf&scoped=true&lang=css&"
import style1 from "./OurWork.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "435139cf",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VCard,VCardTitle,VContainer,VFlex,VHover,VImg,VLayout,VLazy,VSheet,VSlideGroup,VSlideItem})
