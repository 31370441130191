<template>
    <div id="footer" class="footer">
        <div class="contact-info">
            <v-container class="py-0" :class="$vuetify.breakpoint.mdAndUp ? 'half-bg left' : ''">
                <v-row>
                    <v-flex xs12 md6 class="pa-6 center-all" :class="!$vuetify.breakpoint.mdAndUp ? 'full-bg' : ''" style="z-index: 1;">
                        <v-row style="max-width: 32rem;">
                            <v-flex xs12 v-for="contact in contact_info" :key="contact.id" class="contact">
                                <v-lazy transition="scroll-y-transition">
                                    <a :href="contact.url">
                                        <v-icon color="white" class="pa-2" :to="contact.url">{{ contact.icon }}</v-icon>
                                        {{ contact.text }}
                                    </a>
                                </v-lazy>
                            </v-flex>
                        </v-row>
                    </v-flex>
                    <v-flex xs12 md6 v-if="$vuetify.breakpoint.smAndDown">
                        <iframe :src="map_url" frameborder="0" allowfullscreen="allowfullscreen" class="map-mobile" style="border: 0px;"></iframe>
                    </v-flex>
                </v-row>
            </v-container>
            <div v-if="$vuetify.breakpoint.mdAndUp" class="map-container">
                <iframe :src="map_url" frameborder="0" allowfullscreen="allowfullscreen" class="map" style="border: 0px;"></iframe>
            </div>
        </div>
        <v-footer color="primary">
            <v-container fluid>
                <v-layout row>
                    <v-flex xs12 md6 :class="$vuetify.breakpoint.mdAndUp ? 'text-left' : 'text-center'">
                        &copy; {{ new Date().getFullYear() }} <strong><a href="/">{{ $appName }}</a></strong>. - Αρ. Γ.Ε.ΜΗ.: 151226208000
                    </v-flex>
                    <v-flex xs12 md6 :class="$vuetify.breakpoint.mdAndUp ? 'text-right' : 'text-center'">
                        Web Development by <strong><a href="https://kayioulis.com/" target="_blank">Kostas Kayioulis</a></strong> & <strong><a href="https://webbrain.gr/" target="_blank">WebBrain</a></strong>.
                    </v-flex>
                </v-layout>
            </v-container>
        </v-footer>
    </div>
</template>

<style scoped>
.footer {
    margin-left: 72px;
}
.on-mobile .footer {
    margin-left: 0;
}
.contact-info {
    position: relative;
}
.contact-info, .contact-info > .container, .contact-info > .container > .row {
    height: 100%;
    min-height: 25rem;
    overflow: hidden;
}
.on-mobile .contact-info, .contact-info > .container, .contact-info > .container > .row {
    margin-bottom: -12px;
}
.contact-info .map {
    position: absolute;
    top: 0;
    left: calc(50% + 1.2rem);
    width: calc(50% - 1.2rem);
    height: 100%;
}
.contact-info .map-mobile {
    width: 100%;
    height: 100%;
    min-height: 20rem;
}
.contact-info .contact {
    position: relative;
    padding: 0.4rem 2rem 0.4rem 3rem;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 2rem;
}
.contact-info .contact .v-icon {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1.6rem;
}
a {
    color: #ffffff !important;
    text-decoration: none;
}
.v-footer {
    padding: 1rem;
    color: #ffffff !important;
    white-space: break-spaces;
}
</style>

<script>
export default {
    name: 'Footer',
    components: {
    },
    props: {
        menu: {
            type: Array,
            required: true
        }
    },
    data () {
		return {
            map_url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3146.513365606067!2d23.641532015643755!3d37.94179757972938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a1bbc2ac35632f%3A0x5ed851e15794e7e8!2zzqbOr867z4nOvc6_z4IgOTEsIM6gzrXOuc-BzrHOuc6sz4IgMTg1IDM1!5e0!3m2!1sel!2sgr!4v1594503450766!5m2!1sel!2sgr',
            contact_info: [
                {
                    icon: 'fas fa-map-marker-alt',
                    text: 'Filonos 91, 7th Floor, Piraeus, Greece 185 43',
                    url: 'https://goo.gl/maps/9pMWbnD8JDbfNk7U7'
                },
                {
                    icon: 'fas fa-phone-alt',
                    text: '+30 2104222668',
                    url: 'tel:+302104222668'
                },
                {
                    icon: 'fas fa-mobile-alt pl-3',
                    text: '+30 6942768467',
                    url: 'tel:+306942768467'
                },
                {
                    icon: 'fas fa-envelope',
                    text: 'info@pipeman.gr',
                    url: 'mailto:info@pipeman.gr'
                },
                {
                    icon: 'fab fa-linkedin',
                    text: 'PipeMAN on LinkedIn',
                    url: 'https://linkedin.com/company/pipeman-p-c/'
                },
            ]
		}
    },
    methods: {
    }
}
</script>