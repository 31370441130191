export default {

    route: {
        home: 'Αρχική',
        portfolio: 'Portfolio',
        services: 'Υπηρεσίες',
        company_profile: 'Η εταιρία'
    },

    drawer: {
        contact_c2a: {
            title: 'Επικοινωνήστε μαζί μας',
            description: 'Ενδιαφέρεστε για τις υπηρεσίες μας;'
        }
    },

    intro: {
        title: 'Η επόμενη γενιά',
        subtitle: 'στον επισκευαστικό και κατασκευαστικό τομέα στη ναυτιλία και στη βιομηχανία',
        slides: {
            company_info: {
                title: 'Η εταιρία',
                text: 'Η PipeMAN, ενεργεί στην επισκευή του εμπορικού ναυτικού και της βιομηχανίας, παρέχοντας υπηρεσίες ζωτικής σημασίας τεχνικής ακεραιότητας και πολιτικών ασφάλειας.'
            },
            marine: {
                title: 'Ναυτιλία',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quam tellus, molestie eget mauris ut, fringilla aliquet nulla. Aenean ornare congue elementum.'
            },
            industry: {
                title: 'Βιομηχανία',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quam tellus, molestie eget mauris ut, fringilla aliquet nulla. Aenean ornare congue elementum.'
            }
        }
    },

    why_choose_us: {
        title: 'Γιατί να μας επιλέξετε',
        text: `
            Η <span>PipeMAN</span> αν και νεοσύστατη εταιρεία έχει έμπειρους τεχνικούς στον τομέα των κατασκευών και επισκευών σε δίκτυα όλων των ειδών.<br>
            Η οργάνωση και ο σχεδιασμός της εταιρείας εμπνέει εμπιστοσύνη στον πελάτη ως προς  την αποπεράτωση και την ποιότητα των εργασιών όπου αποτελούν δέσμευση μας.<br>
            Η <span>PipeMAN</span> αναλαμβάνει εργασίες παγκοσμίως παρέχοντας ασφαλεία και αξιοπιστία.`,
        c2a: 'Δείτε την Εταιρική Ταυτότητα μας'
    },

    our_work: {
        title: 'Η δουλειά μας μιλάει από μόνη της',
        c2a: 'Δείτε το Portfolio μας'
    },

    portfolio: {
        all: 'Όλα',
        category: 'Κατηγορία'
    },

    services: {
        title: 'Οι υπηρεσίες που σας παρέχουμε',
        c2a: 'Μάθετε περισσότερα για τις υπηρεσίες μας',
        read_more: 'Διαβάστε περισσότερα',
        see_related_work: 'Δείτε δουλειές μας',
        design: {
            name: 'Σχεδιασμός',
            description: 'Η PipeMAN παρέχει την δυνατότητα προσομοίωσης του υπό κατασκευής δικτύου σε σχέδιο για την σαφή εικόνα τυχόν ιδιαιτεροτήτων και αποκλίσεων.'
        },
        fabrication: {
            name: 'Κατασκευές',
            description: 'Η PipeMAN ανταποκρίνεται στο ακέραιο σε οποιαδήποτε απαίτηση έχει ο πελάτης ως προς τα υλικά και τις προδιαγραφές. Οι προκατασκευές ελέγχονται από Μηχανικό Συγκολλήσεων όπου πραγματοποιεί ελέγχους NDT προ και μετά των συγκολλήσεων, επίσης καλύπτουν πάντα τις τεχνικές προδιαγραφές χωρίς να παρεκκλίνουν από το αρχικό στάδιο/μελέτη.'
        },
        installation: {
            name: 'Εγκαταστάσεις',
            description: 'Η PipeMAN ακολουθεί πιστά τον σχεδιασμό των δικτύων με ασφάλεια και υπευθυνότητα. Εκτελεί την τοποθέτηση των προκατασκευασμένων τμημάτων με ακρίβεια στα σημεία που υποδεικνύουν τα σχέδια λόγω της καλής προετοιμασίας που έχει προηγηθεί στο χώρο μας από τους εξειδικευμένους τεχνικούς μας.'
        },
        repair: {
            name: 'Επισκευές',
            description: 'Η PipeMAN διαθέτει τεχνικούς με πολυετή πείρα και τεχνογνωσία στο κομμάτι της επισκευής. Προσφέρουμε τις υπηρεσίες μας σε επισκευές δικτύων, κύριων και βοηθητικών μηχανημάτων πληρώντας τις προδιαγραφές του κατασκευαστή και των εκάστοτε απαιτήσεων του πελάτη.'
        },
        testing: {
            name: 'Δοκιμές',
            description: 'Η PipeMAN διαθέτει εγκεκριμένο προσωπικό σε NDT εργασίες (PT-MT-UT) ώστε να καλύπτει πλήρως τις απαιτήσεις του πελάτη, ενώ επίσης διαθέτει εξοπλισμό εκτέλεσης υδραυλικής δοκιμής δικτύων όπου με την παράδοση του έργου δίδεται Pressure Test Report.'
        },
        certifications: {
            name: 'Πιστοποιήσεις',
            description: 'Η εταιρεία διαθέτει πιστοποιήσεις οι οποίες προσκομίζονται πριν την εκτέλεση των εργασιών, κατ’ απαίτηση του πελάτη και του εκάστοτε επιθεωρητή.'
        }
    },

    company_profile: {
        text: `
            Η PipeMAN είναι μια νεοσύστατη εταιρεία η οποία δημιουργήθηκε από πτυχιούχους και πιστοποιημένους τεχνικούς στον τομέα της επισκευής του εμπορικού Ναυτικού και της βιομηχανίας. Υπό την ομπρέλα της, καλύπτει τον έλεγχο, την επισκευή και την πιστοποίηση όλων των κύριων και βοηθητικών μηχανημάτων σε πλοίο ή εργοστάσιο.<br><br>

            Η εταιρεία μας χαρακτηρίζεται ως μια από τις ανερχόμενες δυνάμεις στον επισκευαστικό τομέα τόσο για τον σύγχρονο εξοπλισμό συγκολλήσεων, διαμόρφωσης δικτύων, τους χρόνους αποπεράτωσης και παράδοσης των εργασιών όσο και για τις ανταγωνιστικές τιμές, την άριστη συνεργασία και παρουσία στο εργασιακό περιβάλλον.<br><br>

            Οι καταρτισμένοι τεχνικοί μας αναλαμβάνουν εργασίες εν πλω και εν ορμώ, όπως επίσης προκατασκευή δικτυων στο εργοστάσιο μας βάσει σχεδίου. Τα υλικά τα οποία χρησιμοποιούνται συνοδεύονται πάντα από πιστοποιητικά καταλληλότητας όπως επίσης και οι τεχνίτες και η εταιρεία, άπαντες. Οι εργασίες που αφορούν συγκολλήσεις παρακολουθούνται από μηχανικό συγκολλήσεων.<br><br>

            Στόχος μας είναι η παράδοση των εργασιών εντός των προ συμφωνηθέντων χρόνων και τεχνικών προδιαγραφών.<br><br>

            Η ασφάλεια πάνω από όλα.`
    }
}