<template>
    <div class="info-items" :style="`${background ? 'background-color: ' + background + ';' : ''} ${light_text ? 'color: #ffffff;' : ''}`">
        <v-container fluid>
            <v-layout row>
                <v-flex xs12 v-if="section_title">
                    <h1 class="headline-title" :class="light_text ? 'white--text' : ''">{{ section_title }}</h1>
                </v-flex>
                <v-flex xs12 :class="`sm${sm_cols} md${md_cols} lg${lg_cols} xl${xl_cols}`" v-for="(item, i) in items" :key="i">
                    <div class="info-item">
                        <v-img class="icon rounded-xl elevation-2" :src="require('@/assets/services/' + item.icon)" :aspect-ratio="1"></v-img>
                        <h2 v-if="item.title && !h3_titles">{{ $vuetify.lang.t(item.title) }}</h2>
                        <h3 v-if="item.title && h3_titles">{{ $vuetify.lang.t(item.title) }}</h3>
                        <div v-if="item.related_work" class="text-center pb-4">
                            <router-link class="p-btn text-center" :to="item.related_work">{{ $vuetify.lang.t('$vuetify.services.see_related_work') }}</router-link>
                        </div>
                        <p v-if="item.description && !hide_description && $vuetify.breakpoint.mdAndUp" class="description">{{ $vuetify.lang.t(item.description) }}</p>
                        <v-expansion-panels v-else-if="item.description && !hide_description" flat accordion>
                            <v-expansion-panel>
                                <v-expansion-panel-header expand-icon="fas fa-chevron-down small-icon ml-2 mt-1" style="width: max-content; margin: 0 auto;">
                                    {{ $vuetify.lang.t('$vuetify.services.read_more') }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="text-justify">
                                    {{ $vuetify.lang.t(item.description) }}
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<style scoped>
.info-items {
    padding: 2rem;
}
.info-items .layout.row {
    justify-content: center;
}
.section-title {
    text-align: center;
    margin-bottom: 1rem;
}
.info-item {
    padding: 2rem;
}
.info-item .icon {
    height: 10rem;
    width: 10rem;
    margin: 0 auto;
    margin-bottom: 1.2rem;
}
.info-item h2, .info-item h3 {
    text-align: center;
    white-space: pre-line;
    margin-bottom: 1rem;
    font-weight: 700;
}
.info-item h2 {
    font-size: 1.5rem;
}
.info-item p {
    text-align: center;
}
.info-item .description {
    opacity: 0;
    transition: opacity 0.8s;
}
.info-item:hover .description {
    opacity: 1;
}
</style>

<script>
export default {
    name: 'InfoIcons',
    props: {
        section_title: {
            type: String,
            required: false
        },
        h3_titles: {
            type: Boolean,
            required: false,
            default: false
        },
        sm_cols: {
            type: Number,
            required: false,
            default: 6
        },
        md_cols: {
            type: Number,
            required: false,
            default: 4
        },
        lg_cols: {
            type: Number,
            required: false,
            default: 4
        },
        xl_cols: {
            type: Number,
            required: false,
            default: 2
        },
        items: {
            type: Array,
            required: true
        },
        light_text: {
            type: Boolean,
            required: false,
            default: false
        },
        background: {
            type: String,
            required: false
        },
        hide_description: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>