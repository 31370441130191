<template>
    <div class="heading">
        <v-container fluid class="fill-height">
            <v-layout :row="$vuetify.breakpoint.smAndDown ? true : false">
                <v-flex xs12 md6 class="center-all">
                    <div class="p-title primary--text">{{ heading }}</div>
                </v-flex>
                <v-flex xs12 md6 class="center-all">
                    <img v-if="cutout == 3" :src="require('@/assets/cutout-03.svg')">
                    <img v-else-if="cutout == 2" :src="require('@/assets/cutout-02.svg')">
                    <img v-else :src="require('@/assets/cutout-01.svg')">
                </v-flex>
            </v-layout>
            <img class="scroll-down" :src="require('@/assets/scroll_animation.gif')">
        </v-container>
    </div>
</template>

<style scoped>
.heading {
    position: relative;
    height: 80vh;
    background-image: linear-gradient(to bottom, rgba(79, 101, 142, 0.7), var(--v-primary-base)), url('~@/assets/intro-background.jpeg');
    background-size: cover;
    background-attachment: fixed;
}
.scroll-down {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    height: 5rem;
    padding: 1rem;
}
.on-mobile .heading {
    height: 100%;
}
.p-title {
    font-size: 3.5rem;
    font-weight: 900;
    padding: 2rem;
}
.on-mobile .p-title, .on-mobile .p-subtitle {
    text-align: center;
}
.p-subtitle {
    font-size: 1.5rem;
}
.heading .container {
    background-color: #fff;
}
.on-mobile .heading .layout {
    margin-top: 6rem;
    margin-bottom: 6rem;
}
.on-mobile .heading .layout.row {
    margin-bottom: 0;
}
.heading .flex:nth-of-type(2) {
    padding: 4rem;
}
.heading .flex img {
    background-image: linear-gradient(to bottom, rgba(79, 101, 142, 0.7), var(--v-primary-base)), url('~@/assets/intro-background.jpeg');
    background-size: cover;
    background-attachment: fixed;
    height: 70%;
    max-height: 720px;
    box-shadow:inset 0px 0px 0px 1px #fff;
}
</style>

<script>
export default {
    name: 'Heading',
    props: {
        heading: {
            type: String,
            required: true
        },
        cutout: {
            type: Number,
            required: false,
            default: 1
        }
    }
}
</script>