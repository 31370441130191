import Vue from 'vue'
import VueRouter from 'vue-router'
import vuetify from '@/plugins/vuetify'

import Home from '@/views/Home'
import Portfolio from '@/views/Portfolio'
import Services from '@/views/Services'
import CompanyProfile from '@/views/CompanyProfile'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: '$vuetify.route.home',
		component: Home
	},
	{
		path: '/portfolio',
		redirect: '/portfolio/all'
	},
	{
		path: '/portfolio/:category',
		name: '$vuetify.route.portfolio',
		component: Portfolio
	},
	{
		path: '/services',
		name: '$vuetify.route.services',
		component: Services,
		meta: {
			services: true
		}
	},
	{
		path: '/company',
		name: '$vuetify.route.company_profile',
		component: CompanyProfile
	}
]

const router = new VueRouter({
	scrollBehavior() {
		if (router.history._startLocation.includes('portfolio') && router.currentRoute.path.includes('portfolio')) return
		let hash = router.currentRoute.hash
		if (!hash || hash == '') return { x: 0, y: 0 }
	},
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	document.title = to.name ? vuetify.framework.lang.t(to.name) + " | " + Vue.prototype.$appName : Vue.prototype.$appName
	next()
})

export default router
