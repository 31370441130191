<template>
    <div id="services" class="parallax-container">
        <div id="parallax-zoom" class="parallax-zoom zero-animation">
            <img class="img" :src="require('@/assets/boat_white_cropped.jpg')">
			<div class="zoomed-img-container">
				<div class="zoomed-img"></div>
				<div class="zoomed-video"><video id="zoomed-video" autoplay loop muted playsinline webkit-playsinline></video></div>
			</div>
        </div>
		<div id="texts" class="texts">
			<div class="text zero top-center">
				<div class="text-container">
					<p><img :src="require('@/assets/scroll_animation.gif')" style="margin-top: 13rem; height: 5rem;"></p>
				</div>
			</div>
			<div class="text-space"></div>
			<div class="text propulsion center-right center-all">
				<div class="text-container">
					<h1>Propulsion Shafting Systems</h1>
					<p>Highly trained workforce, with specialized technical knowledge and extensive experience in repairing main Propulsion Shafting Systems, including Controllable Pitch Propellers, Stern Tube and Intermediate Bearings.</p>
				</div>
			</div>
			<div class="text load-test center-right center-all">
				<div class="text-container">
					<h1>Independent Load Testing</h1>
					<p>Inspection and service for independent load testing with certified personnel and equipment, using certified water bags and approved load cells for fixed and portable locations. </p>
				</div>
			</div>
			<div class="text boiler center-right center-all">
				<div class="text-container">
					<h1>Boiler Maintenance</h1>
					<p>We coordinate manufacture, commerce and installation of high quality boilers for ships and factories around the world. Specialized staff, design, weld, factory test and assemble parts of any given type of boiler on board with experienced riding teams.</p>
				</div>
			</div>
			<div class="text appliances center-right center-all">
				<div class="text-container">
					<h1>Life Saving Appliances & Fire Fighting Equipment</h1>
					<p>Maintenance, Testing and Inspection of life saving appliances for immediate use. A wide range of inspection & certification services are also provided on the Lifesaving equipment for all kind of vessels. Our quality services are approved by leading safety manufactures.</p>
				</div>
			</div>
			<div class="text lifeboat center-right center-all">
				<div class="text-container">
					<h1>Lifeboats & Lifeboat Davit and Life Raft</h1>
					<p>Servicing and maintenance of lifeboats and rescue boats, launching appliances, release gear and davit-launched liferaft automatic release hooks for the following Makers:</p>
					<ul>
						<li>ARIM MACHINERY & ENG CO</li>
						<li>WUXI HAI HONG BOAT MAKING CO LTD</li>
						<li>ZHEJIANG HENGXIN SHIP EQUIPMENTS CO. LTD, HENGXIN BOUYEAN</li>
					</ul>
				</div>
			</div>
			<div class="text main-engine center-right center-all">
				<div class="text-container">
					<h1>Main Engine & Auxiliary Machinery Maintenance</h1>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris eget leo massa. In eu massa quis mauris ornare varius. Quisque a suscipit magna. Duis augue turpis, pulvinar sit amet aliquam ullamcorper, ultrices ut nisi. Pellentesque fermentum metus orci, ultricies iaculis nunc vehicula eget.</p>
				</div>
			</div>
			<div class="text water-ballast center-right center-all">
				<div class="text-container">
					<h1>Water Ballast Treatment System</h1>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris eget leo massa. In eu massa quis mauris ornare varius. Quisque a suscipit magna. Duis augue turpis, pulvinar sit amet aliquam ullamcorper, ultrices ut nisi. Pellentesque fermentum metus orci, ultricies iaculis nunc vehicula eget.</p>
				</div>
			</div>
			<div class="text accommodation center-right center-all">
				<div class="text-container">
					<h1>Accommodation Ladder</h1>
					<p>We provide an extensive load testing service using certified water bags, capable to execute load tests on Accommodation and Pilot embarkation Ladders.</p>
				</div>
			</div>
			<div class="text cargo center-right center-all">
				<div class="text-container">
					<h1>Provision & Cargo Gear</h1>
					<p>Inspection and service for cargo gear equipment and provision with certified personnel and equipment. We provide an extensive load testing service using certified water bags and load cells.<br><br>We are able to execute load tests on: Cranes/lifting appliances & Davits, for the Certification of the Cargo Gear Survey and associated Personnel Transfer basket.</p>
				</div>
			</div>
			<div class="text ut center-right center-all">
				<div class="text-container">
					<h1>NDT & Ultrasonic Thickness Measurement</h1>
					<p>We perform non-destructive test (NDT) inspection and measurements; according to the Classification Society rules and attending surveyor’s requirements. We can undertake inspecting and marking of hull structure areas and supervise the repair progress, through our riding team supervisors or during docking repairs.<br><br>Our NDT Department is qualified to address:</p>
					<ul>
						<li>Penetration Test (PT)</li>
						<li>Ultrasonic Test (UT)</li>
						<li>Magentic Particle Inspection (MPI)</li>
					</ul>
				</div>
			</div>
			<div class="text inspection center-right center-all">
				<div class="text-container">
					<h1>BioFouling Inspection Device</h1>
					<p>The device we offer, allows improved underwater hull management, with respect to (a) a/f coating performance monitoring and (b) planning & execution of the underwater maintenance.<br><br>By deploying the device periodically and consistently, throughout the vessel’s activity; we quantify the results to complement the Vessel’s energy efficiency and biofouling management plan(s).</p>
				</div>
			</div>
			<div class="text underwater center-right center-all">
				<div class="text-container">
					<h1>Underwater Maintenance</h1>
					<p>We are the appointed Agents for IMS Diving Panama, a commercial diving company established in Panama.  With one of the best groups of divers, technicians and engineers in Panama, located this strategic area; we coordinate underwater maintenance to hull fouling, In Water Survey and steel repairs.</p>
				</div>
			</div>
			<div class="text deck center-right center-all">
				<div class="text-container">
					<h1>Deck Machinery</h1>
					<p>Inspection and service for deck machinery equipment with certified personnel and equipment. Our team of professional service engineers provide customized solutions for equipment testing requirements, by offering high quality, efficient and cost-effective professional services worldwide.</p>
				</div>
			</div>
		</div>
    </div>
</template>

<style scoped>
.parallax-container {
	position: relative;
	height: 1500vh;
}
.parallax-zoom {
    width: 100%;
    height: 100vh;
    position: sticky;
	top: 0;
    overflow: hidden;
	padding: 1rem;
	background-color: #ffffff;
}
.parallax-zoom .img {
	width: 100%;
	height: auto;
    display: block;
	position: absolute;
	object-fit: contain;
    transition: 800ms transform ease-in-out, 800ms all ease-in-out;
	/* transition: all 0.8s linear; */
}
.on-mobile .parallax-zoom .img {
	transition: 500ms transform ease-in-out, 500ms all ease-in-out;
}
.parallax-zoom.close-up.zoom .img {
	filter: blur(2px);
}
.parallax-zoom.close-up .img {
	top: 50%;
	filter: blur(1px);
	transform: translateY(-50%) scale(1.5);
}
@media only screen and (max-width: 960px) {
	.parallax-zoom.close-up.zoom .img {
		filter: blur(1px);
	}
	.parallax-zoom.close-up .img {
		filter: blur(0);
		transform: translateY(-50%) scale(1.8);
	}
}
.parallax-zoom .zoomed-img-container {
	width: 50%;
	height: 100%;

	position: absolute;
}
.parallax-zoom .zoomed-img, .parallax-zoom .zoomed-video {
	width: 20rem;
	height: 20rem;

	position: absolute;
	transition: transform 1s linear;

	top: calc(50% - 10rem);
	left: 30%;
	transform: scale(0);

	background-size: cover;
	border-radius: 50%;

	/* filter: drop-shadow(-0.2rem -0.2rem 1rem #ffffff); */
}
.on-mobile .parallax-zoom .zoomed-img, .on-mobile .parallax-zoom .zoomed-video {
	transition: transform 0.5s linear;
}
.parallax-zoom .zoomed-video video {
	width: 20rem;
	height: 20rem;
	object-fit: cover;
	border-radius: 50%;
}
@media only screen and (max-width: 960px) {
	.parallax-zoom .zoomed-img, .parallax-zoom .zoomed-video {
		width: 15rem;
		height: 15rem;
		top: calc(50% - 6.25rem);
		left: 35%;
	}

	.parallax-zoom .zoomed-video video {
		width: 15rem;
		height: 15rem;
	}
}
@media only screen and (max-width: 768px) {
	.parallax-zoom .zoomed-img, .parallax-zoom .zoomed-video {
		left: 25%;
	}
}
@media only screen and (max-width: 600px) {
	.parallax-zoom .zoomed-img, .parallax-zoom .zoomed-video {
		left: 10%;
	}
}
@media only screen and (max-width: 375px) {
	.parallax-zoom .zoomed-img, .parallax-zoom .zoomed-video {
		left: -15%;
	}
}
.texts {
	position: absolute;
	height: 100%;
	width: 100%;
	max-width: 50%;
	right: 0;
	top: 0;
	background-color: rgba(255, 255, 255, 0);
	transition: background-color 0.5s ease-in-out;
}
@media only screen and (min-width: 961px) {
	.texts.texts-background {
		background-color: rgba(255, 255, 255, 0.8);
	}
}
.texts .text-space {
	height: 50vh;
}
@media (orientation: portrait) {
	.texts .text-space {
		height: 25vh;
	}	
}
.texts .text {
	text-align: center;
	height: 100vh;
	padding: 1rem;
	transition: visibility 0s, opacity 1.8s ease-in-out;
	/* text-shadow: -0.05rem -0.05rem 1rem hsla(0,0%,100%,.5), 0.05rem 0.05rem 1rem hsla(0,0%,100%,.5), 0 0 2rem hsla(0,0%,100%,.5), 0 0 4rem hsla(0,0%,100%,.5), 0 0 6rem hsla(0,0%,100%,.5), 0 0 8rem hsla(0,0%,100%,.5), 0 0 10rem hsla(0,0%,100%,.5); */

	font-family: 'Montserrat', sans-serif;
	color: var(--v-primary-base);
	font-weight: bold;
}
.texts .text .text-container {
	font-weight: 400;
	padding: 1rem;
	border-radius: 1rem;
}
.texts .text .text-container p {
	font-weight: 400;
	padding: 0 3rem;
}
.texts .text .text-container ul {
	text-align: left;
	margin: 0 4rem;
}
@media only screen and (max-width: 1264px) {
	.texts .text .text-container p {
		padding: 0;
	}
	.texts .text .text-container ul {
		margin: 0;
	}
}
.texts .text h1 {
	font-family: 'BroaderView-Medium', sans-serif;
	text-transform: uppercase;
	font-weight: 400;
	margin-bottom: 0.3rem;
}
@media only screen and (max-width: 960px) {
	.texts {
		max-width: 100%;
		background-color: transparent;
	}
	.texts .text .text-container {
		background-color: rgba(255, 255, 255, 0.8);
	}
}
/* Top Text Positions */
.texts .text.top-center {
	left: 50%;
	transform: translateX(-50%);
}
.texts .text.top-left {
	top: 0;
	left: 0;
}
.texts .text.top-right {
	top: 0;
	right: 0;
}
/* Bottom Text Positions */
.texts .text.bottom-left {
	bottom: 0;
	left: 0;
}
.texts .text.bottom-right {
	bottom: 0;
	right: 0;
}
/* Center Text Positions */
.texts .text.center-left {
	left: 0;
	top: 50%;
	transform: translateY(-50%);
}
.texts .text.center-right {
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

/* If mobile, all texts on top */
@media only screen and (max-width: 960px) {
	.texts .text.top-left, .texts .text.top-right, .texts .text.center-left, .texts .text.bottom-left, .texts .text.bottom-right, .texts .text.center-right, .texts .text.top-center {
		left: 0;
		top: 0;
		bottom: auto;
		transform: none;
	}
}

/* Zero Animation */
.parallax-zoom.zero-animation .img {
	width: 80%;
	max-width: 60rem;
	margin: 0 auto;
	left: 0;
	right: 0;
	bottom: 0;
}
.parallax-zoom.zero-animation .anchor-up {
	display: none;
}
/* propulsion Animation */
.parallax-zoom.propulsion-animation .img {
	left: -70%;
	margin-top: -25%;
}
.parallax-zoom.propulsion-animation.zoom .zoomed-img {
	transform: scale(1);
	background-image: url('/img/parallax-zoom/propeller.jpg');
}
/* load-test Animation */
.parallax-zoom.load-test-animation .img {
	left: -55%;
	margin-top: -8%;
}
.parallax-zoom.load-test-animation.zoom .zoomed-img {
	transform: scale(1);
	background-image: url('/img/parallax-zoom/load-test.jpg');
}
/* boiler Animation */
.parallax-zoom.boiler-animation .img {
	left: -70%;
	margin-top: 5%;
}
.parallax-zoom.boiler-animation.zoom .zoomed-img {
	transform: scale(1);
	background-image: url('/img/parallax-zoom/boiler.jpg');
}
/* appliances Animation */
.parallax-zoom.appliances-animation .img {
	left: -55%;
	margin-top: 10%;
}
.parallax-zoom.appliances-animation.zoom .zoomed-img {
	transform: scale(1);
	background-image: url('/img/parallax-zoom/appliances.jpg');
}
/* lifeboat Animation */
.parallax-zoom.lifeboat-animation .img {
	left: -38%;
	margin-top: 2%;
}
.parallax-zoom.lifeboat-animation.zoom .zoomed-img {
	transform: scale(1);
	background-image: url('/img/parallax-zoom/lifeboat.jpg');
}
/* Main Engine Animation */
.parallax-zoom.main-engine-animation .img {
	left: -52%;
	margin-top: -15%;
}
.parallax-zoom.main-engine-animation.zoom .zoomed-img {
	transform: scale(1);
	background-image: url('https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg');
}
/* Water Ballast Animation */
.parallax-zoom.water-ballast-animation .img {
	left: -9%;
	margin-top: -2%;
}
.parallax-zoom.water-ballast-animation.zoom .zoomed-img {
	transform: scale(1);
	background-image: url('/img/parallax-zoom/water-ballast.jpg');
}
/* accommodation Animation */
.parallax-zoom.accommodation-animation .img {
	left: 0%;
	margin-top: 10%;
}
.parallax-zoom.accommodation-animation.zoom .zoomed-img {
	transform: scale(1);
	background-image: url('/img/parallax-zoom/accommodation.jpg');
}
/* cargo Animation */
.parallax-zoom.cargo-animation .img {
	left: -5%;
	margin-top: 25%;
}
.parallax-zoom.cargo-animation.zoom .zoomed-img {
	transform: scale(1);
	background-image: url('/img/parallax-zoom/cargo.jpg');
}
/* ut Animation */
.parallax-zoom.ut-animation .img {
	left: 20%;
	margin-top: 15%;
}
.parallax-zoom.ut-animation.zoom .zoomed-img {
	transform: scale(1);
	background-image: url('/img/parallax-zoom/ut.jpg');
}
/* inspection Animation */
.parallax-zoom.inspection-animation .img {
	left: 29%;
	margin-top: 13%;
}
.parallax-zoom.inspection-animation.zoom .zoomed-img {
	transform: scale(1);
	background-image: url('/img/parallax-zoom/inspection.jpg');
}
/* underwater Animation */
.parallax-zoom.underwater-animation .img {
	left: 38%;
	margin-top: 13%;
}
.parallax-zoom.underwater-animation.zoom .zoomed-img {
	transform: scale(1);
	background-image: url('/img/parallax-zoom/underwater.jpg');
}
.parallax-zoom.underwater-animation.zoom .zoomed-video {
	transform: scale(1);
}
/* deck Animation */
.parallax-zoom.deck-animation .img {
	left: 45%;
	margin-top: 32%;
}
.parallax-zoom.deck-animation.zoom .zoomed-img {
	transform: scale(1);
	background-image: url('/img/parallax-zoom/deck.jpg');
}
</style>

<script>
export default {
    name: 'ServicesBoat',
    data: () => ({
	}),
	mounted() {
	},
	methods: {
		on_scroll() {
			let steps = ['zero', 'propulsion', 'load-test', 'boiler', 'appliances', 'lifeboat', 'main-engine', 'water-ballast', 'accommodation', 'cargo', 'ut', 'inspection', 'underwater', 'deck']

			let mobile = window.innerWidth < 960

			this.scroll_stepper(mobile ? 2 : 1, steps)
		},
		scroll_stepper(start, steps) {
			let scroll = window.scrollY
			let vh = window.innerHeight

			let step = scroll - start * vh
			step = (step > 0 ? step : 0) / vh
			step = step >= steps.length ? steps.length - 1 : step

			let step_int = parseInt(step)

			if(step_int != 0) {
				document.getElementById('texts').classList.add('texts-background')
			} else {
				document.getElementById('texts').classList.remove('texts-background')
			}

			this.switch_animation(steps[step_int])

			// console.log('curr step: ' + step_int + `(${step})`)

			// if(step > step_int + 0.9) {
			// 	let prev_step = step_int
			// 	window.scrollTo(0, (prev_step + start) * vh)
			// 	console.log('goto: ' + prev_step)
			// } else if(step > step_int + 0.1) {
			// 	let next_step = step_int + 1
			// 	window.scrollTo(0, (next_step + start) * vh)
			// 	console.log('goto: ' + next_step)
			// }
		},
		switch_animation(animation) {
			let parallax = document.getElementById('parallax-zoom')
			let defClasslist = animation != 'zero' ? `parallax-zoom ${animation}-animation close-up` : `parallax-zoom ${animation}-animation`
			if(!parallax.classList.contains(`${animation}-animation`)) {
				parallax.classList = defClasslist
				// document.documentElement.style.overflow = 'hidden'
				setTimeout(function() {
					if(parallax.classList.contains(`${animation}-animation`)) {
						// document.documentElement.style.overflow = 'auto'
						parallax.classList = defClasslist + ' zoom'
						if(animation == 'underwater') document.getElementById('zoomed-video').src = '/img/parallax-zoom/underwater.mp4'
					}
				}, 600)
			}
			
		}
	}
}
</script>