export default {

    route: {
        home: 'Home',
        portfolio: 'Portfolio',
        services: 'Services',
        company_profile: 'Company Profile'
    },

    drawer: {
        contact_c2a: {
            title: 'Contact Us',
            description: 'Interested in our services?'
        }
    },

    intro: {
        title: 'The next generation',
        subtitle: 'in the construction & repairs of commercial navy and industry',
        slides: {
            company_info: {
                title: 'The Company',
                text: 'PipeMAN is acting in the repair of the merchant navy and industry, providing services with vital technical integrity and safety policies.'
            },
            marine: {
                title: 'Marine',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quam tellus, molestie eget mauris ut, fringilla aliquet nulla. Aenean ornare congue elementum.'
            },
            industry: {
                title: 'Industry',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quam tellus, molestie eget mauris ut, fringilla aliquet nulla. Aenean ornare congue elementum.'
            }
        }
    },

    why_choose_us: {
        title: 'Why Choose Us',
        text: `
            Although a recently established company, PipeMAN has specialized staff in the field of production and repairs of all brands of pipelines.<br>
            The method and plan of the company inspire the confidence of the client in terms of completion and quality of work where they are our engagement.<br>
            PipeMAN coordinates services around the world providing reliability and safety.
            Safety above all.`,
        c2a: 'View our Company Profile'
    },

    our_work: {
        title: 'Our Work speaks for itself',
        c2a: 'View our Portfolio'
    },

    portfolio: {
        all: 'All',
        category: 'Category'
    },

    services: {
        title: 'The services we offer you',
        c2a: 'Learn More about our services',
        read_more: 'Read More',
        see_related_work: 'See our work',
        design: {
            name: 'Design',
            description: 'PipeMAN provides the ability to simulate the under-construction pipelines in a drawing for a clear picture of any characteristics or deviations.'
        },
        fabrication: {
            name: 'Fabrications',
            description: 'PipeMAN completely responds to any customer\'s requirements regarding materials and specifications. The prefabricated materials are inspected by a Professional Welding Engineer who performs non-destructive test (NDT) inspections before and upon completion of welds, also always covers the technical specifications without deviating from the initial scope of work.'
        },
        installation: {
            name: 'Installations',
            description: 'PipeMAN loyally follows the design of pipelines, carefully and responsibly, performs the installation of the prefabricated parts with precision in the points designated by the drawings.'
        },
        repair: {
            name: 'Repairs',
            description: 'PipeMAN with guarantee repairs any part of a line of independent material until the part is replaced by a new line of similar material with relevant specifications.'
        },
        testing: {
            name: 'Tests',
            description: 'PipeMAN has qualified personnel in NDT work (PT-MT-UT) to fully cover customer\'s requirements, in addition to having equipment for hydraulic network testing where a Pressure Test Report is given with each project\'s delivery.'
        },
        certifications: {
            name: 'Certifications',
            description: 'The company has certifications that are presented before the execution of the works, at the request of the client and the respective inspector.'
        }
    },

    company_profile: {
        text: `
            PipeMAN is a newly established company created by graduates and certified technicians in the field of repair of the merchant Navy and industry. Under its umbrella, covers the inspection, repair and certification of all the main and auxiliary machines on the vessel or factory.<br><br>

            Our company is characterized as one of the rising forces in the repair sector both for the modern welding equipment, network configuration, delivery of the work as well as for competitive prices, excellent cooperation, and presence in the working environment.<br><br>
            
            Our trained technicians undertake work onboard and in a rush, as well as prefabrication of networks in our factory according to construction plans. The materials used are always accompanied by certificates of suitability as well as the craftsmen and the company. Welding operations are supervised by a welding engineer.<br><br>
            
            Our goal is to deliver the project within the pre-arranged times and technical specifications.<br><br>
            
            Safety above all.`
    }
}