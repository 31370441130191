<template>
    <v-app-bar v-if="$vuetify.breakpoint.smAndDown" prominent dense flat color="soft-background primary--text" fixed class="pa-0">
        <div class="menu-icon" :class="drawer ? 'open' : ''" @click="openDrawer()">
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
        </div>

        <div class="language-switch center-all">
            <v-btn icon @click="toggleLang()">
                <img v-if="$vuetify.lang.current == 'el'" :src="require('@/assets/greek.svg')"/>
                <img v-else :src="require('@/assets/english.svg')"/>
            </v-btn>
        </div>

        <v-spacer></v-spacer>

        <router-link class="logo" to="/"><img :src="require('@/assets/logo.png')"></router-link>
    </v-app-bar>
    <div v-else>
        <v-navigation-drawer fixed app floating mini-variant mini-variant-width="72px" :mobile-breakpoint="0" color="soft-background">
            <div class="menu-icon" :class="drawer ? 'open' : ''" @click="openDrawer()">
                <div class="bar1"></div>
                <div class="bar2"></div>
                <div class="bar3"></div>
            </div>
            <v-spacer></v-spacer>

            <div class="language-switch center-all">
                <v-btn icon @click="toggleLang()">
                    <img v-if="$vuetify.lang.current == 'el'" :src="require('@/assets/greek.svg')"/>
                    <img v-else :src="require('@/assets/english.svg')"/>
                </v-btn>
            </div>
        </v-navigation-drawer>
        <router-link v-if="$route.path != '/'" class="float-logo" to="/"><img :src="require('@/assets/logo.png')"></router-link>
    </div>
</template>

<style scoped>
.menu-icon {
    display: inline-block;
    cursor: pointer;
    margin: 0 1rem;
}
.v-navigation-drawer .menu-icon {
    margin: 4rem 1.5rem;
    transform: scale(1.3);
}
.menu-icon .bar1, .menu-icon .bar2, .menu-icon .bar3 {
    width: 24px;
    height: 3px;
    background-color: var(--v-primary-base);
    margin: 6px 0;
    transition: 0.4s;
}

.menu-icon.open .bar1 {
    /* -webkit-transform: rotate(-45deg) translate(-9px, 6px); */
    transform: rotate(-45deg) translate(-6px, 7px);
}

.menu-icon.open .bar2 { opacity: 0; }

.menu-icon.open .bar3 {
    /* -webkit-transform: rotate(45deg) translate(-8px, -8px); */
    transform: rotate(45deg) translate(-5.5px, -7px);
}

.float-logo {
    position: absolute;
    top: 3rem;
    right: 3rem;
    z-index: 6;
}
.logo > img, .float-logo > img {
    height: 100%;
    max-height: 64px;
}

.v-navigation-drawer .language-switch {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 4rem 1.2rem;
    transform: scale(1.2);
}
.language-switch img {
    width: 1.8rem;
    height: 1.8rem;
}
</style>

<style>
.v-toolbar--prominent .v-toolbar__content {
    align-items: center !important;
}
</style>

<script>
export default {
    name: 'Toolbar',
    components: {
    },
    props: {
        menu: {
            type: Array,
            required: true
        },
        drawer: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        menu_start () {
            let menu_length = ~~(this.menu.length / 2)

            return this.menu.slice(0, menu_length)
        },
        menu_end () {
            let menu_length = ~~(this.menu.length / 2)

            return this.menu.slice(menu_length)
        }
    },
    methods: {
        openDrawer () {
            this.$emit('drawer')
        },
        toggleLang () {
            this.$vuetify.lang.current = this.$vuetify.lang.current == 'en' ? 'el' : 'en'
            localStorage.setItem('lang', this.$vuetify.lang.current)
        }
    }
}
</script>

