<template>
    <v-navigation-drawer fixed temporary class="app-menu" :width="$vuetify.breakpoint.mdAndUp ? 'calc(100% - 72px)' : '100%'" right :bottom="$vuetify.breakpoint.smAndDown" v-model="drawer">
        <router-link v-if="$vuetify.breakpoint.mdAndUp" class="float-logo" to="/"><img :src="require('@/assets/logo.png')"></router-link>
        <v-container class="fill-height">
            <v-layout row>
                <v-container fluid>
                    <v-layout row>
                        <v-flex xs12 sm6 md4 v-for="item in menu" :key="item.name">
                            <v-lazy v-if="drawer" transition="scale-transition">
                                <h1><router-link :to="item.to">{{ $vuetify.lang.t(item.name) }}</router-link></h1>
                            </v-lazy>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-layout>
            <v-layout row>
                <v-flex v-if="$vuetify.breakpoint.mdAndUp" xs12 md6 class="d-flex align-end" style="padding: 0 2rem;">
                    <p class="mb-0" style="font-size: 1.1rem; font-weight: 600;">
                        &copy; {{ new Date().getFullYear() }} <router-link to="/" style="text-decoration: none;">{{ $appName }}</router-link>.
                    </p>
                </v-flex>
                <v-flex xs12 md6 :class="$vuetify.breakpoint.mdAndUp ? 'center-all' : ''">
                    <div class="contact-c2a" @click="contact()">
                        <h2 v-if="$vuetify.breakpoint.mdAndUp">{{ $vuetify.lang.t('$vuetify.drawer.contact_c2a.description') }}</h2>
                        <h1>{{ $vuetify.lang.t('$vuetify.drawer.contact_c2a.title') }}</h1>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
    </v-navigation-drawer>
</template>

<style scoped>
.float-logo {
    position: fixed;
    top: 3rem;
    right: 3rem;
}
.float-logo > img {
    height: 100%;
    max-height: 64px;
}
h1 > a {
    display: inline-block;
    position: relative;
    color: var(--v-primary-base);
    text-decoration: none;
    font-size: 3rem;
    margin: 2rem;
    font-weight: 900;
}
.on-mobile h1 > a {
    font-size: 2rem;
}
h1 > a:before, .contact-c2a:before {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: -1rem;
    left: 0;
    background: var(--v-primary-base);
    width: 100%;
    height: 3px;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    transition: -webkit-transform .6s cubic-bezier(.215,.61,.355,1) 0s;
    transition: transform .6s cubic-bezier(.215,.61,.355,1) 0s;
    transition: transform .6s cubic-bezier(.215,.61,.355,1) 0s,-webkit-transform .6s cubic-bezier(.215,.61,.355,1) 0s;
}
.contact-c2a:before {
    background: var(--v-accent-base);
}
h1 > a:hover:before, h1 > a.router-link-exact-active:before, .contact-c2a:hover:before {
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: -webkit-transform .6s cubic-bezier(.215,.61,.355,1) 0s;
    transition: transform .6s cubic-bezier(.215,.61,.355,1) 0s;
    transition: transform .6s cubic-bezier(.215,.61,.355,1) 0s,-webkit-transform .6s cubic-bezier(.215,.61,.355,1) 0s;
}

.contact-c2a {
    position: relative;
    display: inline-block;
    color: var(--v-accent-base);
    text-decoration: none;
    cursor: pointer;
}
.on-mobile .contact-c2a {
    margin: 2rem;
}
.contact-c2a h1 {
    font-size: 2.2rem;
    font-weight: 900;
}
.on-mobile .contact-c2a h1 {
    font-size: 1.8rem;
}
.contact-c2a h2 {
    font-size: 1.5rem;
    font-weight: 400;
}
.on-mobile .contact-c2a h2 {
    font-size: 1.2rem;
}
</style>

<style>
.app-menu .container.fill-height > .layout {
    flex: 1 1 100%;
    height: unset;
}
.app-menu.v-navigation-drawer--bottom.v-navigation-drawer--is-mobile {
    max-height: calc(100% - 96px);
}
.app-menu .v-navigation-drawer__content {
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>

<script>
export default {
    name: 'Drawer',
    props: {
        value: {
            type: Boolean,
            required: true
        },
        menu: {
            type: Array,
            required: true
        }
    },
    computed: {
        drawer: {
            get () {
                return this.value
            },
            set (newValue) {
                if(newValue == false) {
                    this.$emit('drawer')
                }
            }
        }
    },
    methods: {
        contact () {
            this.drawer = false
            document.getElementById('footer').scrollIntoView()
            setTimeout(() => {  document.getElementById('footer').scrollIntoView() }, 500)
        }
    }
}
</script>