<template>
    <div class="portfolio-item" :class="background ? 'secondary white--text' : ''">
        <v-lazy transition="scroll-y-transition">
            <v-container class="py-0">
                <v-row v-bind:id="`work${work.id}`">
                    <v-flex xs12 class="py-6">
                        <h1 class="headline-title mx-6 my-0">{{ work.title }}</h1>
                        <h3 class="category-name mx-6">{{ $vuetify.lang.t('$vuetify.portfolio.category') }}: {{ work.category | camelCase }}</h3>

                        <!-- <v-expand-transition>
                            <v-img v-if="model !== null && model !== undefined" contain :src="'/portfolio/images/' + encodeURI(work.images[model])" width="100%" height="60vh"></v-img>
                        </v-expand-transition> -->

                        <p v-if="work.text" v-html="work.text" class="mt-2 mb-0 mx-6"></p>

                        <v-slide-group v-model="model" :dark="background" class="pa-4" center-active>
                            <v-slide-item v-for="(image, i) in work.images" :key="i" v-slot:default="{ active, toggle }">
                                <v-card class="ma-4" height="100" width="100" @click="!active ? toggle() : null">
                                    <v-img :src="'/portfolio/images/' + encodeURI(image)" :gradient="active ? 'to bottom, rgba(79, 101, 142, 0.8), rgba(79, 101, 142, 0.8)' : ''" height="100" width="100"></v-img>
                                </v-card>
                            </v-slide-item>
                        </v-slide-group>
                    </v-flex>
                </v-row>
            </v-container>
        </v-lazy>

        <v-dialog v-model="dialog_model" content-class="image-dialog">
            <v-card class="image-card" tabindex="0" @keyup.left="previous ? model-- : null" @keyup.right="next ? model++ : null" ref="imageCard">
                <v-img v-if="model !== null && model !== undefined" contain :src="'/portfolio/images/' + encodeURI(work.images[model])" width="100%" max-height="90vh" @click="dialog_model = false"></v-img>

                <v-btn fab small depressed color="white" class="nav-btn prev" :disabled="!previous" @click.stop="model--"><v-icon>fas fa-chevron-left</v-icon></v-btn>
                <v-btn fab small depressed color="white" class="nav-btn next" :disabled="!next" @click.stop="model++"><v-icon>fas fa-chevron-right</v-icon></v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>

<style scoped>
.category-name {
    font-weight: 600;
}
.image-card {
    position: relative;
}
.image-card .nav-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.image-card .nav-btn.prev {
    left: 0.5rem;
    padding-right: 2px;
}
.image-card .nav-btn.next {
    right: 0.5rem;
    padding-left: 2px;
}
</style>

<style>
.image-dialog, .image-dialog .v-card, .image-dialog .v-card .v-image {
    max-width: max-content;
}
</style>

<script>
export default {
    name: 'PortfolioItem',
    props: {
        work: {
            type: Object,
            required: true
        },
        background: {
            type: Boolean,
            required: false
        }
    },
    data () {
        return {
            model: null
        }
    },
    watch: {
        dialog_model (v) {
            if (!v) return
            // console.log('dialog opened')
            setTimeout(() => {
                if (this.$refs.imageCard && this.$refs.imageCard.$el) this.$refs.imageCard.$el.focus()
            }, 300)
        }
    },
    computed: {
        dialog_model: {
            get () {
                return (this.model || this.model == 0)
            },
            set (v) {
                if (!v) this.model = null
            }
        },
        previous: {
            get () {
                return !!this.work?.images[this.model - 1]
            }
        },
        next: {
            get () {
                return !!this.work?.images[this.model + 1]
            }
        }
    }
}
</script>