import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

import en from './i18n/en'
import el from './i18n/el'

export default new Vuetify({
    lang: {
        locales: { en, el },
        current: 'en'
    },
    icons: {
        iconfont: 'fa',
    },
    theme: {
        themes: {
            light: {
                primary: '#4f658e',
                secondary: '#3b4e76',
                background: '#ffffff',
                'soft-background': '#ecedf0',
                accent: '#414a58'
            }
        },

        options: {
            customProperties: true,
        }
    },
})
