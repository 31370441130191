import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

import axios from 'axios'
import VueKinesis from 'vue-kinesis'

Vue.config.productionTip = false
Vue.prototype.$appName = 'PipeMAN'

window.axios = axios.create()

Vue.use(VueKinesis)

Vue.filter('camelCase', (text) => {
	return text.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase() })
})

new Vue({
	router,
	vuetify,
	render: h => h(App)
}).$mount('#app')
